import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './PaymentForm.css'; // Import CSS file for styling
import { useAuth } from './Auth/AuthContext';
import { useLoader } from './LoaderContext';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const PaymentForm = ({ campaign }) => {
    const { setIsLoading } = useLoader();
        const { token } = useAuth();
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
      line1: '',
      postal_code: '',
      city: '',
      state: '',
      country:'',
    number: '',
    exp_year: '',
    exp_month: '',
    cvc: '',
    campaign_id: 0
  });

  useEffect(() => {
    // If price prop changes, update the price in formData
    setFormData(prevFormData => ({
      ...prevFormData,
      campaign_id: campaign.id
    }));
  }, [campaign]);
  // Update form data
  const handleChange = (e) => {
    const { name, value } = e.target;

      setFormData({
        ...formData,
        [name]: value
      });

  };

  // Handle form submission
  const handleSubmit = async (e) => {

    e.preventDefault();
    for (const key in formData) {
        console.log(key);
  if (formData[key] === '') {
    setErrors(prevErrors => ({
      ...prevErrors,
      [key]: `Please fill in the ${key.replace('_', ' ')}`
    }));
    // Stop further processing
  }
}
        
     console.log(errors);
      // Validate name field (only alphabets)
      if (!/^[a-zA-Z]+$/.test(formData.name)) {
        alert('Name field should contain only alphabets');
        return false; // Stop further processing
      }
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1; // Adding 1 since getMonth returns 0-based index
        const { exp_year, exp_month } = formData;
        //   if (
        //     parseInt(exp_year, 10) < currentYear ||
        //     (parseInt(exp_year, 10) === currentYear && parseInt(exp_month, 10) <= currentMonth)
        //   ) {
        //     alert('Please provide a future expiry date for the card');
        //     return false; // Stop further processing
        //   }
      if (!/^\d+$/.test(formData.number)) {
        alert('Card NO should contain only numbers');
        return false; // Stop further processing
      }
    
      // Validate postal code and number fields (only numbers)
      if (!/^\d+$/.test(formData.postal_code)) {
        alert('Postal code should contain only numbers');
        return false; // Stop further processing
      }
      if (!/^\d+$/.test(formData.number)) {
        alert('Number should contain only numbers');
        return false; // Stop further processing
      }
    
    

    try {
        setIsLoading(true)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/payment`,
        formData,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if(response.data.charge.status =="succeeded"){
        setIsLoading(false)
   
        Swal.fire("Payment","Payment Succesfull","success")
        navigate('/campaign-management')
      }
      else{
        Swal.fire("!Error","Payment Failed","error")

        setIsLoading(false)
      }
     
  
      // Handle response data as needed
    } catch (error) {
      console.error("Error creating campaign:", error);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>Payment Details</h2>
        </div>
        <div className="modal-body">
          <div className="left-column">
            <div className="input-group">
              <label htmlFor="cardNumber">Card Number:</label>
              <input type="text" id="cardNumber" name="number" value={formData.number} onChange={handleChange} placeholder="Enter card number" />
               <span style={{"color":"red"}}>{errors.number}</span>
            </div>
            <div className="input-group">
              <label htmlFor="cardName">Name on Card:</label>
              <input type="text" id="cardName" name="name" value={formData.name} onChange={handleChange} placeholder="Enter name on card" />
              <span style={{"color":"red"}}>{errors.name}</span>

            </div>
            <div className="input-group">
              <label htmlFor="expiry">Expiry Date:</label>
              <input type="text" id="expiry" name="exp_month" value={formData.exp_month} onChange={handleChange} placeholder="MM" />
              <span style={{"color":"red"}}>{errors.exp_month}</span>
              <input type="text" id="expiry" name="exp_year" value={formData.exp_year} onChange={handleChange} placeholder="YY" />
              <span style={{"color":"red"}}>{errors.exp_year}</span>
            </div>
            <div className="input-group">
              <label htmlFor="cvc">CVC:</label>
              <input type="text" id="cvc" name="cvc" value={formData.cvc} onChange={handleChange} placeholder="Enter CVC" />
              <span style={{"color":"red"}}>{errors.cvc}</span>
            </div>
          </div>
          <div className="right-column">
            <div className="input-group">
              <label htmlFor="addressLine">Address Line 1:</label>
              <input type="text" id="addressLine" name="line1" value={formData.line1} onChange={handleChange} placeholder="Enter address line" />
              <span style={{"color":"red"}}>{errors.line1}</span>
            </div>
            <div className="input-group">
              <label htmlFor="postalCode">Postal Code:</label>
              <input type="text" id="postalCode" name="postal_code" value={formData.postal_code} onChange={handleChange} placeholder="Enter postal code" />
              <span style={{"color":"red"}}>{errors.postal_code}</span>
            </div>
            <div className="input-group">
              <label htmlFor="city">City:</label>
              <input type="text" id="city" name="city" value={formData.city} onChange={handleChange} placeholder="Enter city" />
              <span style={{"color":"red"}}>{errors.city}</span>
            </div>
            <div className="input-group">
              <label htmlFor="state">State:</label>
              <input type="text" id="state" name="state" value={formData.state} onChange={handleChange} placeholder="Enter state" />
              <span style={{"color":"red"}}>{errors.state}</span>
            </div>
            <div className="input-group">
              <label htmlFor="country">Country:</label>
              <input type="text" id="country" name="country" value={formData.country} onChange={handleChange} placeholder="Enter country" />
              <span style={{"color":"red"}}>{errors.country}</span>
            </div>
          </div>
          <button className="pay-button" onClick={handleSubmit}>Pay Now</button>
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
