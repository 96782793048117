import React from "react";
const Home = () => {
  return (
    <>
    <main>
     
                <h6 class="text-center font-semibold">Coming Soon</h6>
           
      </main>
    </>
  );
};
export default Home;
